import { Box } from "@mui/material";
import { useBreakpointSelect } from "@edenred/theme";
import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image";
import { ReactNode } from "react";

type HeroProps = {
  data:
    | GatsbyTypes.ContentfulClient["landingHero"]
    | GatsbyOverrides.GatsbyImageDataImage;
  dataSet:
    | GatsbyTypes.ContentfulClient["landingHeroSet"]
    | {
        desktop: GatsbyOverrides.GatsbyImageDataImage;
        tablet: GatsbyOverrides.GatsbyImageDataImage;
        mobile: GatsbyOverrides.GatsbyImageDataImage;
      };
  children: ReactNode;
} & Omit<GatsbyImageProps, "alt" | "image">;

const style = {
  display: {
    xs: "block",
    sm: "grid"
  }
} as const;

const positionCenterStyle = {
  display: {
    sm: "grid"
  },
  gridArea: {
    sm: "1/1"
  },
  position: "relative",
  placeItems: {
    sm: "center"
  },
  mt: 4.5
} as const;

export default function Hero({ children, data, dataSet, ...rest }: HeroProps) {
  const image = useBreakpointSelect({
    lg: dataSet?.desktop ?? data,
    md: dataSet?.tablet,
    sm: dataSet?.mobile
  });

  return (
    <Box component="section" sx={style}>
      {image?.image?.gatsbyImageData && image?.altText && (
        <GatsbyImage
          key={image.image.id}
          loading="eager"
          image={image.image.gatsbyImageData}
          alt={image.altText}
          {...rest}
          style={{ gridArea: "1/1", maxHeight: "620px" }}
        />
      )}
      <Box sx={positionCenterStyle}>{children}</Box>
    </Box>
  );
}
