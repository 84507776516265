import { MicroCopyContext } from "@edenred/micro-copy";
import { Paper, Stack, SxProps, Typography } from "@mui/material";
import { graphql } from "gatsby";
import { ReactNode, useContext } from "react";
import CartIcon from "../images/cart.svg";
import ECodeIcon from "../images/eCode.svg";
import MailIcon from "../images/mail.svg";
import { sxHomePaper } from "../templates/home";

type HowToProps = { data: GatsbyTypes.ContentfulHowTo };

const sxHowToStepNumber: SxProps = {
  backgroundColor: "primary.main",
  width: 42,
  height: 42,
  minHeight: 42,
  borderRadius: "50%",
  mx: "auto",
  mb: "-21px",
  zIndex: "speedDial",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

const sxHowToStep: SxProps = {
  borderRadius: 1,
  backgroundColor: "grey.100",
  pt: 3.75,
  pb: 4,
  px: 2,
  width: 1,
  height: 1,
  justifyContent: "space-between",
  alignItems: "center"
};

export default function HowTo({ data }: HowToProps) {
  const getMicroCopy = useContext(MicroCopyContext);

  const howToStep = (stepNumber: number, image: ReactNode, text?: string) => (
    <Stack sx={{ width: 1 }}>
      <Typography
        sx={sxHowToStepNumber}
        variant="subtitle2"
        color="primary.contrastText"
        align="center"
      >
        {stepNumber}
      </Typography>
      <Stack sx={sxHowToStep} spacing={2}>
        <Typography variant="subtitle2">{text}</Typography>
        {image}
      </Stack>
    </Stack>
  );

  return (
    <Paper sx={{ ...sxHomePaper, mt: 4.5, width: 1 }} variant="outlined">
      <Typography variant="h6">{data.heading}</Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 7.5, sm: 3 }}
        sx={{ mt: 3 }}
      >
        {howToStep(
          1,
          <img src={ECodeIcon} alt={getMicroCopy("howto.ecode.alt")} />,
          data.step1
        )}
        {howToStep(
          2,
          <img src={CartIcon} alt={getMicroCopy("howto.cart.alt")} />,
          data.step2
        )}
        {howToStep(
          3,
          <img src={MailIcon} alt={getMicroCopy("howto.mail.alt")} />,
          data.step3
        )}
      </Stack>
    </Paper>
  );
}

export const query = graphql`
  fragment HowTo on ContentfulHowTo {
    __typename
    heading
    step1
    step2
    step3
  }
`;
