import Container from "@edenred/container";
import { MicroCopyContext } from "@edenred/micro-copy";
import { isMobileMediaQuery } from "@edenred/theme";
import { Box, Grid, Paper, SxProps } from "@mui/material";
import { graphql, PageProps } from "gatsby";
import { useContext, useEffect } from "react";
import ECodeForm from "../components/ECodeForm";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import HowTo from "../components/HowTo";
import Image from "../components/Image";
import Page from "../components/Page";
import { Context } from "../components/Provider";
import { useToken } from "../hooks/useToken";

type Props = PageProps<Pick<GatsbyTypes.Query, "contentfulClient">>;

export const sxHomePaper: SxProps = {
  width: { xs: 1, sm: 320 },
  maxWidth: { xs: 440, sm: 1 },
  pt: 4.5,
  px: 3,
  pb: 3
};

export default function HomePage({ data }: Props) {
  const { contentfulClient } = data;
  const isMobile = isMobileMediaQuery();
  const getMicroCopy = useContext(MicroCopyContext);
  const [, setToken] = useToken();
  const {
    updateECodes,
    updateBasket,
    updateApiRetailer,
    updateCustomer,
    updateRetailerLookup,
    updateOrderPageData
  } = useContext(Context);

  if (!contentfulClient) {
    throw Error("Expected data is missing");
  }

  useEffect(() => {
    setToken();
    updateECodes([]);
    updateBasket([]);
    updateCustomer(null);
    updateApiRetailer(null);
    updateRetailerLookup({});
    updateOrderPageData(null);
  }, []);

  return (
    <Page
      title={getMicroCopy("home.title")}
      description={getMicroCopy("home.description")}
      sx={{ backgroundColor: { xs: "grey.50", sm: "grey.100" } }}
    >
      <Hero
        data={contentfulClient?.landingHero}
        dataSet={contentfulClient?.landingHeroSet}
      >
        <Container>
          <Grid container justifyContent="center">
            <Paper
              sx={sxHomePaper}
              variant={isMobile ? "outlined" : "elevation"}
            >
              {contentfulClient?.logo && (
                <Box
                  sx={{
                    marginBottom: 4.5,
                    textAlign: "center",
                    "& > img": { maxHeight: "66px", maxWidth: "190px" }
                  }}
                >
                  <Image data={contentfulClient.logo} />
                </Box>
              )}
              <ECodeForm
                clientReferenceId={contentfulClient.referenceId}
                disableMultipleEcodes={
                  !!contentfulClient?.disableMultipleEcodes
                }
              />
            </Paper>
            {isMobile && contentfulClient?.howTo && (
              <HowTo data={contentfulClient.howTo} />
            )}
          </Grid>
        </Container>
      </Hero>
      {!isMobile && contentfulClient?.howTo && (
        <Container>
          <Grid container justifyContent="center">
            <HowTo data={contentfulClient.howTo} />
          </Grid>
        </Container>
      )}
      {contentfulClient?.footer && (
        <Footer
          data={contentfulClient.footer}
          sxStyle={{
            mt: !isMobile && !contentfulClient?.howTo ? 0 : 7.5
          }}
        />
      )}
    </Page>
  );
}

export const pageQuery = graphql`
  query HomePageQuery($id: String!) {
    contentfulClient(id: { eq: $id }) {
      ...Client
    }
  }
`;
